import Swiper from 'swiper/js/swiper.min';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar.min'

const $win = $(window);
const $doc = $(document);
const $body = $('body');
const $header = $('.header');
const $sectionIntroImage = $('.section-intro .section__image');
const $firstSection = $('.section-intro-alt + *');
const $introHomeTitleWords = $('.section-intro-alt .section__content h1 span');
const $btnScroll = $('.btn-scroll');
const $btnNav = $('.btn-nav');
const $navFirstLvlLinks = $('.nav a');
const $navItemWithChild = $('.nav li.menu-item-has-children');
const $onloadFade = $('.onload-fade');
const $onloadFadeTop = $('.onload-fade-top');
const $onscrollFadeTopSequence = $('.onscroll-fade-top-sequence');
const $onloadFadeRightAbsolute = $('.onload-fade-right-absolute');
const $onloadFadeBottomTranslate = $('.onload-fade-bottom-translate');
const $onscrollFadeTop = $('.onscroll-fade-top');
const $onscrollFadeTopAbsolute = $('.onscroll-fade-top-absolute');

// Your code goes here...
// jQuery.ready is no longer needed

if ( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	$body.addClass('no-touch');
} else {
	$body.addClass('is-touch');
};

$win.load(() => {
	const queryString = window.location.hash.substr(1);

	if (! queryString) {
		return;
	}

	const $target = $(`[data-id="${queryString}"]`).eq(0);

	if (! $target.length) {
		return;
	}
	
	const $wpAdminBar = $('#wpadminbar');

	let gutter = 
	$wpAdminBar.length
	? $wpAdminBar.outerHeight() + $header.outerHeight() 
	: $header.outerHeight();

	$('html, body').animate({
		scrollTop: $target.offset().top - gutter,
	}, 1000);
});

// Mobile Nav Trigger
$btnNav.click(function(e) {
	e.preventDefault();
	$body.toggleClass('show-nav');
});

// Scroll Button
$btnScroll.click(function(e) {
	e.preventDefault();
	let headerHeight = $header.outerHeight();
	let offset = $firstSection.offset().top - headerHeight;

	$('html, body').animate({
		scrollTop: offset,
	}, 1000);
});



// Submenus
function subMenu() {
	if($body.hasClass('is-touch')) {
		$navFirstLvlLinks.on('click', function(e) {
			const $this = $(this);

			if($this.parent().hasClass('menu-item-has-children')) {
				if(!$this.parent().hasClass('expanded')) {
					e.preventDefault();

					$this
					.parent()
					.addClass('expanded');

					$this
					.next()
					.addClass('active')
					.slideDown();

					$this
					.parent()
					.siblings()
					.removeClass('expanded');

					$this
					.parent()
					.siblings('.menu-item-has-children')
					.children('ul')
					.removeClass('active')
					.slideUp();
				}	
			}
			else {
				if($this.parent().siblings('.menu-item-has-children').hasClass('expanded')) {
					e.preventDefault();

					$this
					.parent()
					.siblings('.menu-item-has-children')
					.removeClass('expanded');

					$this
					.parent()
					.siblings('.menu-item-has-children')
					.children('ul')
					.slideUp();

					$this
					.parent()
					.siblings('.menu-item-has-children')
					.children('ul')
					.removeClass('active');
				}
			}
		});
	}
	else {
		$navItemWithChild.hover(
			function() {
				var $this = $(this);

				$this.children('ul').addClass('active');
			},

			function() {
				var $this = $(this);

				$this.children('ul').removeClass('active');
			}
			)
	}
}

subMenu();



// Accordion
$('.accordion .accordion__section-head').click(function() {
	let $this = $(this);
	let $parent = $this.parent();
	let $siblings = $this.parent().siblings();
	let headerHeight = $header.outerHeight();

	if($parent.is('.expanded')) {
		$parent.removeClass('expanded');
		$this.next().slideUp();
	}
	else {
		$siblings.removeClass('expanded');
		$parent.addClass('expanded');

		$siblings.find('.accordion__section-body').slideUp();
		$this.next().slideDown();
	}

	$('html, body').animate({
		scrollTop: $this.parent().parent().offset().top - headerHeight - 20
	}, 1000);
});



// Slider Services
$win.load(() => {
	const $sliderServices = $('.slider-services');
	const swiperOptions = {
		autoplay: {
			delay: 2000
		},
		loop: true,
		effect: 'fade',
		slidesPerView: 1,
	};

	if ($sliderServices.find('.slider__slide').length < 2) {
		swiperOptions.loop = false;
		swiperOptions.resistanceRatio = 0;
	}

	if($sliderServices.length > 0) {
		let offset = $sliderServices.offset().top;
		var swiperServices = false;

		if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
			swiperServices = new Swiper($sliderServices.find('.swiper-container'), swiperOptions);
		}
		else {
			$win.scroll(function() {
				if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
					if(!swiperServices) {
						swiperServices = new Swiper($sliderServices.find('.swiper-container'), swiperOptions);
					}
				}
			});
		}
	}
});



// Slider Testimonials
$win.load(() => {
	const $sliderTestimonials = $('.slider-testimonials');
	var swiperOptions;
	var swiperTestimonials = {};


	if($sliderTestimonials.length > 0) {
		$sliderTestimonials.each(function(index, elem) {
			let $elem = $(elem);

			swiperOptions = {
				autoplay: {
					delay: 5000
				},
				loop: true,

				pagination: {
					el: $elem.find('.swiper-pagination'),
					type: 'bullets',
					clickable: true,
				},

				grabCursor : true,
			};

			if ($elem.find('.slider__slide').length < 2) {
				swiperOptions.loop = false;
				swiperOptions.resistanceRatio = 0;
				swiperOptions.pagination = false;
				swiperOptions.grabCursor = false;
			}



			swiperTestimonials[index] = new Swiper($elem.find('.swiper-container'), swiperOptions);
		});	
	}
});



// Slider Intro
$win.load(() => {
	const $sliderIntro = $('.slider-intro');
	const swiperOptions = {
		autoplay: {
			delay: 5000
		},
		loop: true,
		effect: 'fade'
	};

	if ($sliderIntro.find('.slider__slide').length < 2) {
		swiperOptions.loop = false;
		swiperOptions.resistanceRatio = 0;
	}

	const swiperTestimonials = new Swiper($sliderIntro.find('.swiper-container'), swiperOptions);
});



// Animations
$win.load(function() {
	if($introHomeTitleWords.length > 0) {
		let time = 0;
		
		$introHomeTitleWords.each(function(index) {
			let $this = $(this);

			setTimeout(function() {
			$this.css({
				'top' : '0',
				'opacity' : '1',
				'visibility' : 'visible'
			})
			}, time);

			time += 100;
		});
	}

	if($sectionIntroImage.length > 0) {
		$sectionIntroImage.addClass('animated');
	}

	if($onloadFadeRightAbsolute.length > 0) {
		$onloadFadeRightAbsolute.css({
			'right' : '0',
			'opacity' : '1'
		});
	}

	if($onloadFadeTop.length > 0) {
		$onloadFadeTop.css({
			'top' : '0',
			'opacity' : '1'
		});
	}

	if($onloadFade.length > 0) {
		$onloadFade.css({
			'opacity' : '1'
		});
	}

	if($onloadFadeBottomTranslate.length > 0) {
		$onloadFadeBottomTranslate.css({
			'transform' : 'translateY(20px)',
			'opacity' : '1'
		});
	}

	if($onscrollFadeTop.length > 0) {
		$onscrollFadeTop.each(function() {
			let $this = $(this);
			let offset = $this.offset().top;

			if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
				$this.css({
					'top' : '0',
					'opacity' : '1',
				});
			}
		});
	}

	if($onscrollFadeTopAbsolute.length > 0) {
		$onscrollFadeTopAbsolute.each(function() {
			let $this = $(this);
			let offset = $this.offset().top;

			if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
				$this.css({
					'top' : '0',
					'opacity' : '1',
				});
			}
		});
	}

	if($onscrollFadeTopSequence.length > 0) {
		var time = 300;

		$onscrollFadeTopSequence.each(function(index) {
			let $this = $(this);
			let offset = $this.offset().top;

			if(index > 0) {
				setTimeout(function() {
					if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
						$this.css({
							'top' : '0',
							'opacity' : '1',
						});
					}
				}, time);

				time += 300;
			}
			else {
				if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
					$this.css({
						'top' : '0',
						'opacity' : '1',
					});
				}
			}
		});
	}
});

$win.scroll(function() {
	if($onscrollFadeTop.length > 0) {
		$onscrollFadeTop.each(function() {
			let $this = $(this);
			let offset = $this.offset().top;

			if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.02) {
				$this.css({
					'top' : '0',
					'opacity' : '1',
				});
			}
		});
	}

	if($onscrollFadeTopSequence.length > 0) {
		var time = 300;

		$onscrollFadeTopSequence.each(function(index) {
			let $this = $(this);
			let offset = $this.offset().top;

			if(index > 0) {
				setTimeout(function() {
					if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
						$this.css({
							'top' : '0',
							'opacity' : '1',
						});
					}
				}, time);

				time += 300;
			}
			else {
				if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
					$this.css({
						'top' : '0',
						'opacity' : '1',
					});
				}
			}
		});
	}
});

$win.scroll(function() {
	if($onscrollFadeTopAbsolute.length > 0) {
		$onscrollFadeTopAbsolute.each(function() {
			let $this = $(this);
			let offset = $this.offset().top;

			if($win.scrollTop() + $win.outerHeight() >= offset + $win.outerHeight()*.05) {
				$this.css({
					'top' : '0',
					'opacity' : '1',
				});
			}
		});
	}
});

function InitParallax() {
	const $container = $('[data-parallax="true"]');

	if(!$container.length) {
		return false;
	}

	const _move = 100;

	let _arrListTopOffset    = [];
	let _arrListBottomOffset = [];
	let _WinScroll           = 0;

	$win.scroll(function(event) {
		_WinScroll = $win.scrollTop();
		
		Parallax();
	});

	$win.on('load resize orientationchange', function() {
		Offsets();
	})

	function Offsets() {
		$container.each(function(i) {
			const $this   = $(this);
			const _top    = parseInt($this.offset().top - $win.height(), 10);
			const _bottom = parseInt(_top + $this.innerHeight() + $win.height(), 10);

			_arrListTopOffset[i]    = (_top < 0) ? 0 : _top;
			_arrListBottomOffset[i] = _bottom;
		});
	}

	function Parallax() {
		$container.each(function(i){
			if(_WinScroll >= _arrListTopOffset[i] && _WinScroll <= _arrListBottomOffset[i]){

				const $this      = $(this);
				const _height    = _arrListBottomOffset[i] -  _arrListTopOffset[i];
				const _scroll    = _height - (_arrListBottomOffset[i] - _WinScroll);
				const _position  = $this.data('parallax-move'); 
				const _dimension = ($this.data('dimension') != undefined) ? $this.data('dimension') : _move;

				let _percentage = parseInt((_scroll / _height) * 100);			
					_percentage = (_percentage >= 99) ? 100 : _percentage;

				if(typeof _percentage === 'number'){
					const _scrollParallax = parseInt((_percentage / 100) * _dimension);

					if(_position == 'top') {
						$this.css({
							'-webkit-transform' : 'translateY(-' + _scrollParallax + 'px)',
							   '-moz-transform' : 'translateY(-' + _scrollParallax + 'px)',
							    '-ms-transform' : 'translateY(-' + _scrollParallax + 'px)',
									'transform' : 'translateY(-' + _scrollParallax + 'px)'
						});
					}

					if(_position == 'bottom') {
						$this.css({
							'-webkit-transform' : 'translateY(' + _scrollParallax + 'px)',
							  '-moz-transform' : 'translateY(-' + _scrollParallax + 'px)',
							   '-ms-transform' : 'translateY(-' + _scrollParallax + 'px)',
									'transform' : 'translateY(' + _scrollParallax + 'px)'
						});
					}

					if(_position == 'opacity') {
						$this.css({
							opacity: ((_percentage * 2) / 100)
						});
					}
				}
			}
		});
	}
}

$win.load(InitParallax());

var $sidebars = $('.section-timeline .section__row');
$sidebars.each(function(index, elem) {
	let $elem = $(elem);

	$elem.attr('id', 'sticky-' + index);
});

var sidebarsAmount = $sidebars.length - 1;

function initSticky() {
	var stickySidebars = {};	

	$sidebars.each(function(index, elem) {
		let $elem = $(elem);
		let topSpacing = $header.outerHeight() + ($('#wpadminbar').length > 0 ? $('#wpadminbar').outerHeight() : 0);

		stickySidebars[index] = new StickySidebar('#' + $elem.attr('id') + ' ' + '.section__aside', {
			containerSelector: '#' + $elem.attr('id') + ' ' + '.section__row-inner',

			innerWrapperSelector: '#' + $elem.attr('id') + ' ' + '.section__aside-inner',

			topSpacing: topSpacing + 10,

			bottomSpacing: 0,

			resizeSensor: true,
		});
	});
}

function reinitSticky() {
	$win.resize(function() {
		if (stickySidebars) {
			for(let i = 0; i <= sidebarsAmount; i++ ) {
				stickySidebars[i].destroy();
			}

			initSticky();
		}
	});
}

$win.load(function() {	
	if($('.section-timeline .section__aside').length) {
		initSticky();
	}
});